import settings from '../settings';
import fadeout from './fadeout';
import appInit from './app';


function userListPos() {
  let $ul = $('#item .users-list ul');
  let $lis = $ul.find('li, .li');

  if (!settings.__isMobile && $lis.length > 5) {
    if (!$ul.hasClass('slick-initialized')) {
      $ul.slick({
        slidesToShow: 5,
        slidesToScroll: 5,
        dots: false,
        arrows: true,
        autoplay: false,
        centerMode: false,
        infinite: false
      });
    }
  } else {
    if ($ul.hasClass('slick-initialized')) {
      $ul.slick('unslick');
    }
    $ul.addClass('js-fadeout fadeout')
      .attr({
        'data-mobile-only': true,
        'data-max-width': 1000
      });
    fadeout.fadeoutInit($ul.parent());
  }
}

export default {
  init() {
    if ($('#item .users-list').length) {
      appInit.addToCallbacks(userListPos);
      userListPos();
    }
  },
};
