<template>
  <main>
    <div class="holder">
      <back-button />

      <section
        v-if="goods.owner.id !== null && goods.owner.id === user.id"
        id="item"
        class="my cols"
        :class="{ 'no-chats': chats.length === 0 }"
      >
        <div class="col">
          <div v-if="chats.length" class="users-list">
            <ul>
              <li v-for="chat in chats" class="act">
                <a class="avatar" @click="goToChatWithRecipient(chat.id)">
                  <span class="img-holder">
                    <img
                      v-if="chat.recipient.resourceId"
                      :src="createResourceUrl(chat.recipient.resourceId)"
                      :alt="chat.recipient.name" style="box-shadow: none !important;"
                    />
                    <img
                      v-else
                      src="../../public/images/icons/ico_account.jpg"
                      :alt="chat.recipient.name" style="box-shadow: none !important;"
                    />
                  </span>
                  {{ chat.recipient.name }}
                  <span v-if="chat.unreadMessagesCount" class="count">{{
                    chat.unreadMessagesCount
                  }}</span>
                </a>
              </li>
            </ul>
          </div>

          <div class="info">
            <div class="karmitts">{{ goods.cost }}</div>
            <a class="category sport">
              <img
                :src="createResourceUrl(goods.category.resourceId)"
                :alt="goods.category.name"
                class="svg"
              />{{ goods.category.name }}</a
            >
            <div class="views">{{ goods.viewCount }}</div>
            <div class="rating">{{ goods.favoriteCount }}</div>
          </div>

          <h1>{{ goods.title }}</h1>
          <div class="text" style="white-space: pre-wrap;">
            <p>{{ goods.description }}</p>
            <p v-if="goods.geo != null && goods.geo.description != null && goods.geo.description != ''">{{$t("goods.address")}} {{goods.geo.description}}</p>
          </div>

          <div v-if="goods.completed != null"
                  class="time-left"
                  v-html="
              $t('goods.closed', {
                date: $options.filters.expireDateFilter(goods.completed)
              })
            "
          ></div>
          <div
            v-else-if="
              (goods.completed === null || goods.completed === undefined) &&
                goods.expireDate !== null &&
                new Date(goods.expireDate) > new Date()
            "
            class="time-left"
            v-html="
              $t('goods.left', {
                date: $options.filters.expireDateFilter(goods.expireDate)
              })
            "
          ></div>
          <div v-else-if="goods.expireDate" class="time-left"
            v-html="
              $t('goods.expired', {
                date: $options.filters.expireDateFilter(goods.expireDate)
              })
            "
          ></div>

          <div v-if="(goods.bookDate !== null && new Date(goods.bookDate) > new Date())"
                  class="time-left"
                  v-html="
              $t('goods.booked', {
                date: $options.filters.expireDateFilter(goods.bookDate)
              })
            "
          ></div>

          <div id="map" v-if="goods.geo !== null" :key="goods.id">
            <GmapMap
              :center="{ lat: goods.geo.latitude, lng: goods.geo.longitude }"
              :zoom="15"
              map-type-id="terrain"
              style="width: 100%; height: 100%"
              ref="mapRef"
              :options="{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false
              }"
            >
              <GmapMarker
                v-if="!!google"
                :position="{
                  lat: goods.geo.latitude,
                  lng: goods.geo.longitude
                }"
                :draggable="false"
                :icon="{
                  strokeColor: '#FFFFFF',
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  fillColor: '#00D9B2',
                  fillOpacity: 1,
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 15,
                  anchor: new google.maps.Point(0, 0)
                }"
              >
              </GmapMarker>
            </GmapMap>
          </div>

          <div class="btn-line" v-if="goods.completed === null">
            <button
              class="btn remove js-modal-link"
              data-target="#modal-remove-item"
              @click="onDeleteGoodsClicked"
            >
              {{ $t("goods.hide") }}
            </button>
            <button class="btn" @click="onEditClicked">
              {{ $t("goods.edit") }}
            </button>
          </div>
        </div>

        <div class="col">
          <div id="photos">
            <ul
              :class="{ 'js-slider': goods.resourceIds.length > 1 }"
              data-arrows="true"
              v-if="goods.resourceIds.length > 0"
            >
              <li v-for="(item, index) in goods.resourceIds" :key="index">
                <a
                  class="js-lightbox"
                  data-gallery="photos"
                  :href="createResourceUrl(item)"
                >
                  <img
                    :src="createResourceUrl(item)"
                    alt="#"
                    title="#"
                    v-if="item"
                  />
                </a>
                <span
                  :style="
                    'background-image: url(' +
                      url +
                      'api/client/resource/' +
                      item +
                      ')'
                  "
                ></span>
              </li>
            </ul>
            <ul v-else>
              <li>
                <a>
                  <img src="../../public/images/Fill.jpg" />
                </a>
                <span
                  :style="
                    'background-image: url(' +
                      url +
                      'api/client/resource/' +
                      item +
                      ')'
                  "
                ></span>
              </li>
            </ul>
            <div class="count">{{ goods.resourceIds.length }}</div>
          </div>
        </div>
      </section>

      <section
        v-else-if="goods.owner.id !== null"
        id="item"
        class="my cols"
        style="padding-top: 0px"
      >
        <div class="col">
          <div class="info">
            <div class="karmitts">{{ goods.cost }}</div>
            <a class="category sport" @click="goToMain(goods.category.id)">
              <img
                :src="createResourceUrl(goods.category.resourceId)"
                :alt="goods.category.name"
                :title="goods.category.name"
                class="svg"
              />{{ goods.category.name }}</a
            >
            <div class="views">{{ goods.viewCount }}</div>
            <div class="rating">{{ goods.favoriteCount }}</div>
          </div>

          <h1>{{ goods.title }}</h1>
          <div class="text">
            <p>{{ goods.description }}</p>
            <p v-if="goods.geo != null && goods.geo.description != null && goods.geo.description != ''">{{$t("goods.address")}} {{goods.geo.description}}</p>
          </div>

          <div
            v-if="
              goods.completed === null &&
                goods.expireDate !== null &&
                new Date(goods.expireDate) > new Date()
            "
            class="time-left"
            v-html="
              $t('deals.left', {
                date: $options.filters.expireDateFilter(goods.expireDate)
              })
            "
          ></div>
          <div v-else-if="goods.expireDate" class="time-left">
            {{
              $t("goods.closed", {
                date: $options.filters.expireDateFilter(goods.expireDate)
              })
            }}
          </div>
          <div v-if="(goods.bookDate !== null && new Date(goods.bookDate) > new Date())"
               class="time-left"
               v-html="
              $t('goods.booked', {
                date: $options.filters.expireDateFilter(goods.bookDate)
              })
            "
          ></div>

          <div id="map" v-if="goods.geo !== null" :key="goods.id">
            <GmapMap
              :center="{ lat: goods.geo.latitude, lng: goods.geo.longitude }"
              :zoom="15"
              map-type-id="terrain"
              style="width: 100%; height: 100%"
              ref="mapRef"
              :options="{
                zoomControl: true,
                mapTypeControl: true,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: true,
                disableDefaultUi: false
              }"
            >
              <GmapMarker
                v-if="!!google"
                :position="{
                  lat: goods.geo.latitude,
                  lng: goods.geo.longitude
                }"
                :draggable="false"
                :icon="{
                  strokeColor: '#FFFFFF',
                  strokeOpacity: 1,
                  strokeWeight: 3,
                  fillColor: '#00D9B2',
                  fillOpacity: 1,
                  path: google.maps.SymbolPath.CIRCLE,
                  scale: 15,
                  anchor: new google.maps.Point(0, 0)
                }"
              >
              </GmapMarker>
            </GmapMap>
          </div>

          <div class="owner">
            <a class="avatar" @click="goToChatWithOwner">
              <img
                v-if="goods.owner.resourceId"
                :src="createResourceUrl(goods.owner.resourceId)"
                :alt="goods.owner.name"
              />
              <img
                v-else
                src="../../public/images/icons/ico_account.jpg"
                :alt="goods.owner.name"
              />
            </a>
            <a class="person" @click="goToChatWithOwner">
              <span class="name">{{ goods.owner.name }}</span>
              {{ goods.owner.rating }}</a
            >
            <a class="btn" @click="goToChatWithOwner">{{
              $t("goods.go-to-chat")
            }}</a>
          </div>

          <div class="complain" @click="onComplaintClick">
            <a style="color: #A7A39C">{{ $t("goods.report") }}</a>
          </div>
        </div>

        <div class="col">
          <div id="photos">
            <ul
              :class="{ 'js-slider': goods.resourceIds.length > 1 }"
              data-arrows="true"
              v-if="goods.resourceIds.length > 0"
            >
              <li v-for="(item, index) in goods.resourceIds" :key="index">
                <a
                  class="js-lightbox"
                  data-gallery="photos2"
                  :href="createResourceUrl(item)"
                >
                  <img
                    :src="createResourceUrl(item)"
                    alt="#"
                    title="#"
                    v-if="item"
                  />
                </a>
                <span
                  :style="
                    'background-image: url(' +
                      url +
                      'api/client/resource/' +
                      item +
                      ')'
                  "
                ></span>
              </li>
            </ul>
            <ul v-else>
              <li>
                <a>
                  <img src="../../public/images/Fill.jpg" />
                </a>
                <span
                  :style="
                    'background-image: url(' +
                      url +
                      'api/client/resource/' +
                      item +
                      ')'
                  "
                ></span>
              </li>
            </ul>
            <div class="count">{{ goods.resourceIds.length }}</div>
            <span
              v-if="isAuthenticated"
              class="selected"
              :class="{ act: goods.favorite }"
              @click="onAddToFavoriteClick"
            ></span>
          </div>
        </div>
      </section>

      <div
        v-if="
          moreGoods.length > 1 ||
            (moreGoods.length === 1 && moreGoods[0].id !== $route.params.id)
        "
        id="bl-also"
        class="catalogue"
      >
        <h2 class="h1">{{ $t("goods.more") }}</h2>

        <ul class="items">
          <li
            v-for="item in moreGoods"
            :key="item.id"
            v-if="item.id !== $route.params.id"
          >
            <a @click="openItem(item)">
              <span class="title"
                ><strong>{{ item.title }}</strong></span
              >
              <span
                class="time-left"
                :class="{ hot: item.expireDate < 3600000 }"
                v-if="item.expireDate"
                v-html="
                  $t('goods.left-2', {
                    date: $options.filters.expireDateFilter(item.expireDate)
                  })
                "
              ></span>
            </a>
            <span
              class="bg"
              :style="
                'background-image: url(' +
                  url +
                  'api/client/resource/' +
                  item.favoriteResourceId +
                  ')'
              "
            ></span>
            <span
              v-if="isAuthenticated"
              class="selected"
              :class="{ act: item.favorite }"
              @click="onAddToFavoriteFromListClick(item)"
            ></span>
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import expireDateFilter from "../assets/filters/expireDateFilter.js";
import BackButton from "../components/layouts/BackButton.vue";
import imageSlider from "../assets/js/init/imageSlider";
import userSlider from "../assets/js/init/userSlider";
import modal from "../assets/js/init/modal";
import lightbox from "../assets/js/init/lightbox";
import { gmapApi } from "gmap-vue";
import { eventBus } from "../main";
import { baseUrl } from "../appConfig.js";

export default {
  name: "Goods",
  title: "Goods",
  components: {
    BackButton
  },
  filters: {
    expireDateFilter
  },
  data() {
    return {
      url: appSettings.baseUrl,
      resizeCallbacks: [],
      goods: {
        id: null,
        title: null,
        description: null,
        geo: null,
        resourceIds: [],
        category: {
          id: null,
          name: null,
          color: null,
          resourceId: null
        },
        viewCount: null,
        favoriteCount: null,
        owner: {
          id: null,
          name: null,
          rating: null,
          resourceId: null
        },
        cost: null,
        expireDate: null,
        completed: null,
        favorite: null,
        bookDate: null
      },
      moreGoods: [],
      chats: [],
      owner: null,
      checkUserInterval: null,
      sliderInitInterval: null,
      moreGoodsInterval: null
    };
  },
  computed: {
    ...mapGetters(["goodsCategories", "user", "isAuthenticated"]),
    google: gmapApi
  },
  created() {
    if (this.$route.params.item !== undefined) {
      this.mapper(this.$route.params.item);
    }
  },
  async mounted() {
    if (this.isAuthenticated) {
      this.loadChats({ goodsId: this.$route.params.id }).then(result => {
        this.chats.push(...result.data);
      });
    }
    await this.loadGoodsById({ id: this.$route.params.id }).then(result => {
      this.mapper(result.data);
      this.fillMeta(result.data);
    });
    lightbox.init();
    let that = this;
    this.moreGoodsInterval = setInterval(function() {
      // если юзер не авторизован или (юзер авторизован, его id получен и id не равен id товара)
      if (
        !that.isAuthenticated ||
        (that.isAuthenticated &&
          that.user.id !== "" &&
          that.goods.owner.id !== that.user.id)
      ) {
        clearInterval(that.moreGoodsInterval);
        that
          .loadGoods({
            style: "list",
            limit: 5,
            ownerId: that.goods.owner.id
          })
          .then(result => {
            that.moreGoods.push(...result.data);
          });
      } else if (that.goods.owner.id === that.user.id) {
        clearInterval(that.moreGoodsInterval);
      }
    }, 50);
    imageSlider.init();
    if (this.user.id === "") {
      let that = this;
      this.checkUserInterval = setInterval(function() {
        if (that.goods.owner.id === that.user.id) {
          clearInterval(that.checkUserInterval);
          that.sliderInitInterval = setInterval(function() {
            if ($("#item .users-list").length) {
              clearInterval(that.sliderInitInterval);
              userSlider.init();
            }
          }, 50);
        } else if (that.user.id !== "") {
          clearInterval(that.checkUserInterval);
        }
      }, 50);
    } else {
      this.sliderInitInterval = setInterval(function() {
        if ($("#item .users-list").length) {
          clearInterval(that.sliderInitInterval);
          userSlider.init();
        }
      }, 50);
    }
  },
  beforeDestroy() {
    if (this.checkUserInterval !== null) {
      clearInterval(this.checkUserInterval);
    }
    if (this.sliderInitInterval !== null) {
      clearInterval(this.sliderInitInterval);
    }
    if (this.moreGoodsInterval !== null) {
      clearInterval(this.moreGoodsInterval);
    }
  },
  methods: {
    ...mapActions([
      "loadGoodsById",
      "loadGoodsCategories",
      "deleteGoods",
      "loadChats",
      "addToFavorite",
      "loadGoods",
      "addToFavoriteSimplified"
    ]),

    createMetaTag(property, content) {
      const tmp = document.createElement("meta");
      tmp.setAttribute("property", property);
      tmp.setAttribute("content", content);
      document.head.appendChild(tmp);
    },

    fillMeta(dto) {
      document.head.querySelector("[property~=description]").remove();
      this.createMetaTag("og:title", "Karmitt - " + dto.title);
      this.createMetaTag("og:url", baseUrl);
      this.createMetaTag("og:description", dto.description);
      this.createMetaTag("description", dto.description);

      if (dto.resourceIds) {
        const img_url =
          appSettings.baseUrl + "api/client/resource/" + dto.favoriteResourceId;
        this.createMetaTag("og:image", img_url);
      }
    },

    mapper(dto) {
      this.goods.id = dto.id;
      this.goods.title = dto.title;

      document.title = this.$t("titles.default") + " — " + dto.title;

      this.goods.description = dto.description;

      if (dto.resourceIds) {
        this.goods.resourceIds.splice(0, this.goods.resourceIds.length);
        this.goods.resourceIds.push(...dto.resourceIds);
      } else {
        this.goods.resourceIds.push(dto.favoriteResourceId);
      }

      if (dto.category) {
        this.goods.category.id = dto.category.id;
        this.goods.category.name = dto.category.name;
        this.goods.category.color = dto.category.color;
        this.goods.category.resourceId = dto.category.resourceId;
      } else if (dto.goodsCategory) {
        this.goods.category.id = dto.goodsCategory.id;
        this.goods.category.name = dto.goodsCategory.name;
        this.goods.category.color = dto.goodsCategory.color;
        this.goods.category.resourceId = dto.goodsCategory.resourceId;
      }

      this.goods.viewCount = dto.viewCount;
      this.goods.cost = dto.cost;
      this.goods.favoriteCount = dto.favoriteCount;
      this.goods.expireDate = dto.expireDate;
      this.goods.bookDate = dto.bookDate;
      this.goods.completed = dto.completed;
      this.goods.owner.id = dto.owner.id;

      if (dto.owner.name.substring(0, 4) !== "i18n") {
        this.goods.owner.name = dto.owner.name;
      } else {
        this.goods.owner.name = dto.owner.name.substring(
          dto.owner.name.lastIndexOf('"ru":"') + 6,
          dto.owner.name.lastIndexOf('"')
        );
      }

      this.goods.owner.rating = dto.owner.rating;
      this.goods.owner.resourceId = dto.owner.resourceId;
      this.goods.favorite = dto.favorite;
      this.goods.geo = dto.geo;
    },
    goToChatWithRecipient(id) {
      this.goToChat(id);
    },
    goToChatWithOwner() {
      if (!this.isAuthenticated) {
        eventBus.$emit("onLoginModalOpen");
        return;
      }
      if (!this.chats.length) {
        this.$router.push({
          name: "newChat",
          params: {
            id: this.$route.params.id,
            goodsId: this.goods.id,
            goodsResource: this.goods.resourceIds[0],
            goodsTitle: this.goods.title,
            goodsOwnerId: this.goods.owner.id
          }
        });
      } else {
        this.goToChat(this.chats[0].id);
      }
    },
    goToChat(id) {
      this.$router.push({
        name: "chat",
        params: {
          id: id,
          goodsId: this.goods.id,
          goodsResource: this.goods.resourceIds[0],
          goodsTitle: this.goods.title,
          goodsOwnerId: this.goods.owner.id,
        }
      });
    },
    goToMain(categoryId) {
      this.$router.push({
        name: "objects",
        params: {
          category: categoryId
        }
      });
    },
    onDeleteGoodsClicked() {
      let that = this;
      eventBus.$emit("areYouSure", {
        text: this.$t("are-you-sure.delete-goods"),
        yesFunc: function() {
          that
            .deleteGoods({ id: that.goods.id })
            .then(() => {
              that.showToast(this.$t("toast.goods-deleted"));
              that.$router.push({ name: "objects" });
            })
            .catch(error => {
              that.showToast(error.data.message, "error");
            });
        }
      });
    },
    createResourceUrl(id) {
      if (id) {
        return appSettings.baseUrl + "api/client/resource/" + id;
      } else {
        return null;
      }
    },
    onAddToFavoriteClick() {
      this.goods.favorite = !this.goods.favorite;
      this.addToFavorite(this.goods);
    },
    onAddToFavoriteFromListClick(item) {
      let object = this.moreGoods.filter(obj => {
        return obj.id === item.id;
      })[0];
      object.favorite = !item.favorite;
      object.type = "goods";
      this.addToFavoriteSimplified(object).catch(() => {
        object.favorite = !object.favorite;
      });
    },
    openItem(item) {
      this.$router.push({
        name: "goods",
        params: {
          id: item.id,
          item: item
        }
      });
    },
    onEditClicked() {
      this.$router.push({
        name: "editItem",
        params: {
          id: this.goods.id,
          goods: this.goods
        }
      });
    },
    onComplaintClick() {
      eventBus.$emit("onOpenComplaint", {
        goodsId: this.goods.id,
        chatId: null
      });
    }
  }
};
</script>

<style scoped>
.no-chats {
  padding-top: 0 !important;
}
</style>
